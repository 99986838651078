
import {
  CategoryTypes,
  TicketCategory,
} from "@/store/modules/category/category.types";
import { Pagination } from "@/types/types";
import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { namespace } from "vuex-class";
import { commaDate, currency, pad, slash, dash, uuid } from "@/filters/utils";

const categoryX = namespace("Category");

@Component({
  components: {
    IDatePicker: () => import("@/components/utils/IDatePicker.vue"),
    AddCategory: () => import("@/components/category/AddCategory.vue"),
    EditCategory: () => import("@/components/category/EditCategory.vue"),
    DeleteCategory: () => import("@/components/category/DeleteCategory.vue"),
  },
  filters: {
    currency,
    slash,
    commaDate,
    pad,
    dash,
    uuid,
  },
})
export default class CategoryCategoriesView extends Vue {
  @categoryX.Mutation(CategoryTypes.SET_ADD_CATEGORY_DIALOG)
  public setAddCategory!: (addCategory: boolean) => void;

  @categoryX.Mutation(CategoryTypes.SET_UPDATE_CATEGORY_DIALOG)
  public setUpdateCategory!: (updateCategory: boolean) => void;

  @categoryX.Mutation(CategoryTypes.SET_DELETE_CATEGORY_DIALOG)
  public setDeleteCategory!: (deleteCategory: boolean) => void;

  @categoryX.Mutation(CategoryTypes.SET_TICKET_CATEGORY_DATA)
  public setTicketCategoryData!: (ticketCategory: TicketCategory) => void;

  @categoryX.Action(CategoryTypes.LOAD_CATEGORYS)
  public getTicketCategories!: (pagination: Pagination) => void;

  @categoryX.State(CategoryTypes.CATEGORIES)
  public ticketCategories!: TicketCategory[];

  @categoryX.State(CategoryTypes.LOADING_CATEGORIES_STATE)
  public loadingCategories!: boolean;

  @categoryX.State("addCategoryRef")
  public addCategoryRef!: number;

  openAddCategoryDialog(): void {
    this.setAddCategory(true);
  }

  public search = "";

  public headers: DataTableHeader[] = [
    {
      text: "#ID",
      sortable: false,
      value: "id",
      cellClass: "primary--text text--darken-2 font-weight-medium",
    },
    { text: "NAME", value: "name", cellClass: "grey--text text--darken-2" },
    {
      text: "DESCRIPTION",
      value: "description",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
    {
      text: "STATUS",
      value: "active",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
    {
      text: "CREATED",
      value: "createdAt",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
    {
      text: "ACTIONS",
      value: "actions",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
  ];

  deleteCategory(item: TicketCategory): void {
    this.setDeleteCategory(true);
    this.setTicketCategoryData(item);
  }

  editCategory(item: TicketCategory): void {
    this.setUpdateCategory(true);
    this.setTicketCategoryData(item);
  }

  mounted(): void {
    this.getTicketCategories({ page: 1, limit: 10 });
  }
}
